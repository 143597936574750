import { useBreakpointIndex } from '@theme-ui/match-media';
import React from 'react';
import { Text,Grid,Box } from 'theme-ui';

export const TextKlarheit = ({ children }) => (
	<Text
		mb="30px"
		sx={{
			fontFamily: 'Ubuntu',
			fontStyle: 'normal',
			fontWeight: '300',
			fontSize: [ '13px', '16px', '18px' ],
			lineHeight: '79.5%',
			textAlign: 'center',
			letterSpacing: '0.02em',
			margin: '0px 0px'
		}}
	>
		{children}
	</Text>
);

export const AußenInnen = () => {
	return (
		<Grid
			gap={['0.3em', '0.5em', '0.5em']}
			sx={{
				// justifyContent: 'center',
				gridTemplateColumns: 'fit-content(300px) fit-content(300px) fit-content(300px)'
			}}
		>
			<TextKlarheit>Klarheit im Außen</TextKlarheit>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Circle/>
			</Box>
			<TextKlarheit>Klarheit im Innen</TextKlarheit>
		</Grid>
	);
};
function Circle() {
	const index = useBreakpointIndex()
	const size = index === 0 ? 8 : 10 
	return <svg width={size} height={size} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="6.5" cy="6" r="6" fill="#3d709c" />
	</svg>;
}

