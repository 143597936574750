module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/fussel/Documents/Entwicklung/gatsby/schoenhausen/gatsby-starter-catalyst-lithium/node_modules/gatsby-remark-images","id":"f00cb159-6fec-5308-a090-84b5a6a689b5","name":"gatsby-remark-images","version":"3.11.1","pluginOptions":{"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/home/fussel/Documents/Entwicklung/gatsby/schoenhausen/gatsby-starter-catalyst-lithium/node_modules/gatsby-theme-catalyst-core/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"rehypePlugins":[],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/fussel/Documents/Entwicklung/gatsby/schoenhausen/gatsby-starter-catalyst-lithium"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/gatsby-browser.js'),
      options: {"plugins":[],"useColorMode":false,"remarkImagesWidth":1920},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-catalyst","short_name":"catalyst","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"content/assets/shoenhausen.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a07f06fa8b7b3b084e8d8c2a4e0b06bf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
