/** @jsx jsx */
import { jsx, useThemeUI, Text, Box, Grid } from 'theme-ui';
import { useContext, useEffect, useState } from 'react';
import Branding from 'gatsby-theme-catalyst-header-top/src/components/branding/branding';
import Nav from './navbar/nav';
import MobileButton from './navbar/nav-mobile-button';
import { NavContext } from 'gatsby-theme-catalyst-core';
import { useCatalystConfig, HomeContext } from 'gatsby-theme-catalyst-core';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { AußenInnen, TextKlarheit } from './TextKlarheit';
import { Link } from 'gatsby';
import FadeIn from 'react-fade-in';
import SchoenAnimation from '../../../content/assets/animation-neu.inline.svg';
import { heroRef } from '../../shared/gloabalref';
import { useLocation } from '@reach/router';

const Animation = () => {
	return (
		<Box sx={{ml: [0,null,'2em'], maxWidth: '550px', overflow: 'visible' }}>
			<SchoenAnimation style={{ overflow: 'visible' }} width="100%" height="100%" />
		</Box>
	);
};
const SiteHeader = () => {
	const [ isNavOpen ] = useContext(NavContext);
	const { useStickyHeader } = useCatalystConfig();
	const { theme } = useThemeUI();
	//const [ isHome ] = useContext(HomeContext);
	const isSSR = typeof window === 'undefined';
	const location = useLocation();
	const isHome = location.pathname === '/';
	//console.log(location.pathname)
	// const bIndex = useBreakpointIndex();
	return (
		<header
			style={{ minHeight: isHome ? '100vh' : undefined }}
			sx={{
				// background:
				// 	'linear-gradient(180deg, #F9C5C9 0%, rgba(227, 195, 166, 0.67) 16.36%, #7DBCA5 43.55%, #57A488 98.27%, #fbf5f1 100%)',
				position: useStickyHeader ? 'sticky' : 'static',
				top: 0,
				width: '100%',
				//	height: '350px',
				color: isNavOpen ? 'header.textOpen' : 'header.text',
				backgroundColor: isNavOpen ? 'header.backgroundOpen' : 'header.background',
				gridArea: 'header',
				zIndex: '888', // Ensure the header is always on top
				variant: 'variants.header',
				pb: 4
			}}
			id="header"
		>
			{/* <div sx={{ position: 'absolute', top: '5px', left: '5px' }}>{bIndex}</div> */}
			<div
				sx={{
					gridRow: '1 / -1',
					gridColumn: '1 / -1',
					alignSelf: 'start',
					display: 'grid',
					position: 'absolute',
					left: '50%' /* position the left edge of the element at the middle of the parent */,
					transform: 'translate(-50%, 0%)',
					gridTemplateColumns: 'auto 1fr',
					gridTemplateRows: [
						theme.sizes.headerHeight + ' 1fr',
						null,
						null,
						theme.sizes.headerHeight,
						null,
						null
					],
					maxWidth: 'maxPageWidth',
					width: '100vw',
					minHeight: isNavOpen ? '100vh' : '50px',
					backgroundColor: isNavOpen ? '#fff9' : 'none',
					m: '0 auto',
					px: [ 1, null, 3, null, null ],
					py: [ 1, null, 2, null, null ]
				}}
			>
				{/* <Branding /> */}
				<Nav />
				<MobileButton />
			</div>

			<div
				onClick={() => isHome && heroRef.current.scrollIntoView()}
				sx={{
					display: 'grid',
					gridTemplateRows: 'auto 1fr',
					height: isHome ? '100vh' : undefined,
					minHeight: isHome ? '670px' : undefined,
					//position: 'absolute',
					//top: '50px',
					// width: '100%',
					color: '#3d709c',
					textAlign: 'center'

					//minHeight: isHome ? '648px' : undefined,
				}}
			>
				<Grid sx={{ mt: '50px', justifyContent: 'center', gridGap: 0, gridTemplateRows: 'auto	 auto' }}>
					<FadeIn transitionDuration={isHome ? 1500 : 500}>
						<Grid sx={{ pl: [ 0, null, '27px' ], gridTemplateColumns: 'auto 1fr', maxWidth: '100vw', mb:['1.5em',null,'2em'] }}>
							<Logo />

							<Box sx={{ textAlign: 'left' }}>
								<Text
									sx={{
										mb: 3,
										fontFamily: 'Ubuntu',
										fontStyle: 'normal',
										fontWeight: '300',
										fontSize: '16px',
										lineHeight: '71.5%'
									}}
								>
									Kira Kayembe
								</Text>
								<Text
									mb="30px"
									sx={{
										fontFamily: 'Ubuntu',
										fontStyle: 'normal',
										fontWeight: '300',
										fontSize: [ '38px', '40.797px', '52.797px' ],
										lineHeight: '71.5%',
										mb: 3
									}}
								>
									SchönHausen
								</Text>
								<AußenInnen />
							</Box>
						</Grid>
					</FadeIn>

					{isHome && <Animation />}
				</Grid>
				{isHome && (
					<div sx={{ alignSelf: 'center', width: '80px', height: '80px', justifySelf: 'center' }}>
						<FadeIn transitionDuration={2000}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="#3d709c" viewBox="0 0 22 22">
								<path d="m3.707031 7l-.707031.707031 6.125 6.125 1.875 1.875 1.875-1.875 6.125-6.125-.707031-.707031-6.125 6.125-1.167969 1.167969-1.167969-1.167969-6.125-6.125" />
							</svg>
						</FadeIn>
					</div>
				)}
			</div>

		</header>
	);
};

export default SiteHeader;

// width="62"
// height="89"
const Logo = () => (
	<Box sx={{ width: [ 62 * 0.8, 62 * 0.9, 62 ] }}>
		<svg
			style={{ alignSelf: 'end' }}
			width="100%"
			height="auto"
			viewBox="0 0 62 89"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M32.6275 25.1735C21.7697 47.7352 50.2461 34.1248 30.0535 79.0029M51.9404 52.5863C51.9404 67.9799 43.0996 80.4588 32.1941 80.4588C21.2885 80.4588 12.4478 67.9799 12.4478 52.5863C12.4478 37.1928 21.2885 24.7138 32.1941 24.7138C43.0996 24.7138 51.9404 37.1928 51.9404 52.5863ZM56.9169 86.9783L5.4875 87.1312L1.35077 21.6033L29.9254 1.6268L60.1982 21.2632L56.9169 86.9783Z"
				stroke="#3d709c"
				stroke-width="3.04222"
			/>
		</svg>
	</Box>
);
