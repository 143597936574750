/** @jsx jsx */
import { jsx, useThemeUI, Styled } from "theme-ui"
import {
  useSiteMetadata,
  useCatalystConfig,
  SocialFooter,
} from "gatsby-theme-catalyst-core"
import { IconContext } from "react-icons"

const SiteFooter = () => {
  const { title } = useSiteMetadata()
  const { footerContentLocation } = useCatalystConfig()
  const { theme } = useThemeUI()
  const isLeft = footerContentLocation === "left"
  const isRight = footerContentLocation === "right"
  const isCenter = footerContentLocation === "center"

  return (
    <footer
      sx={{
        color: "footer.text",
        backgroundColor: "footer.background",
        textAlign:
          (isLeft && "left") || (isRight && "right") || (isCenter && "center"),
        px: 3,
        py: 3,
        gridArea: "footer",
        a: {
          color: "footer.links",
        },
        variant: "variants.footer",
      }}
    >
    							<Styled.p
								id='kontakt'
								//data-sal="slide-right"
								data-sal-duration={2000}
								//data-sal-delay="300"
								data-sal-easing="ease"
								sx={{
									pt: 0,
									mb: 0,
									textAlign: 'center'
									//ml: [ 160, 180, null, 300, 400 ]
								}}
							>
								<span sx={{ color: 'green' }}>Gerne berate ich Dich individuell.
								</span>
							</Styled.p>
							<Styled.p
								//data-sal="slide-right"
								data-sal-duration={2000}
								//data-sal-delay="300"
								data-sal-easing="ease"
								sx={{
									mt: 2,
									mb:0,
									textAlign: 'center'
									//ml: [ 160, 180, null, 300, 400 ]
								}}
							>
								<span sx={{ color: 'blue' }}>Tel. +4915227171575
								</span>
							</Styled.p>
							<Styled.p
								//data-sal="slide-right"
								data-sal-duration={2000}
								//data-sal-delay="300"
								data-sal-easing="ease"
								sx={{
									mt: 0,
									mb: 2,
									textAlign: 'center'
									//ml: [ 160, 180, null, 300, 400 ]
								}}
							>
								<span sx={{ color: 'blue' }}>kayembekira@gmail.com
								</span>
							</Styled.p>

      <div
        sx={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
        //    (isLeft && "start") || (isRight && "end") || (isCenter && "center"),
          width: "100%",
          maxWidth: "maxPageWidth",
          mx: "auto",
          my: 0,
        }}
      >
        <div
          sx={{
            a: {
              color: "footer.icons",
              mr: 3,
            },
            "a:last-of-type": {
              mr: 0,
            },
            "a:hover": {
              color: "primary",
            },
            textAlign: 'center',
            mb: 5
          }}
        >
          <IconContext.Provider value={{ size: theme.sizes.iconsFooter }}>
            <SocialFooter />
          </IconContext.Provider>
        </div>
        <p sx={{ m: 0 }}>
          © {new Date().getFullYear()} {title}
        </p>
      </div>
    </footer>
  )
}

export default SiteFooter
