import { merge } from 'theme-ui';
import { tailwind, baseColors } from '@theme-ui/preset-tailwind';
import { BaseTheme } from 'gatsby-theme-catalyst-core';
import 'fontsource-quando';

export default merge(BaseTheme, {
	// Modifications to the theme go here.
	fonts: {
		...tailwind.fonts,
		body:
			'"Ubuntu",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
		heading: '"Ubuntu","Quando", "Times New Roman", Times, serif',
		monospace: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace'
	},
	breakpoints: [ '390px', '480px', '768px', '1024px', '1440px' ],
	fontWeights: {
		body: 400,
		heading: 400,
		bold: 700
	},
	colors: {
		...tailwind.colors,
		//background: baseColors.gray[1], //Try "#954264",
		text: baseColors.gray[8],
		textGray: '#6b6b6b',
		primary: baseColors.blue[7],
		secondary: baseColors.orange[7],
		accent: baseColors.orange[2],
		highlight: baseColors.orange[5],
		muted: baseColors.gray[2],
		green: '#57A488',
		blue: '#3D709C',
		header: {
			background: baseColors.gray[1],
			backgroundOpen: baseColors.blue[2],
			text: '#3d709c',
			textOpen: '#3d709c',
			icons: '#3d709c',
			iconsOpen: '#3d709c'
		},
		footer: {
			background: 'white',
			text: baseColors.gray[8],
			links: baseColors.gray[8],
			icons: baseColors.gray[8]
		},
		// You can delete dark mode by removing the "mode" object and/or setting useColorMode to false in gatsby-theme-catalyst-core
		modes: {
			dark: {
				background: baseColors.gray[9],
				text: baseColors.gray[1],
				textGray: '#9f9f9f',
				primary: '#6da3dc',
				secondary: baseColors.orange[7],
				accent: baseColors.gray[8],
				highlight: baseColors.orange[5],
				muted: baseColors.gray[8],
				header: {
					background: baseColors.gray[9],
					backgroundOpen: baseColors.gray[8],
					text: baseColors.gray[1],
					textOpen: baseColors.gray[1],
					icons: baseColors.gray[1],
					iconsOpen: baseColors.gray[1]
				},
				footer: {
					background: '#232946',
					text: baseColors.gray[1],
					links: baseColors.gray[1],
					icons: baseColors.gray[1]
				}
			}
		}
	},
	sizes: {
		...tailwind.sizes,
		maxPageWidth: '1000px', // Sets the max width of elements like the header/footer large screens
		maxContentWidth: '720px', // Sets the container size on larger screens, e.g. tablets and laptops
		headerHeight: 'auto', // Provides fallback setting to control header height
		logoWidthXS: '40px', // Logo width on extra small screens, up to 480px
		logoWidthS: '50px', // Logo width on small screens, 480px - 768px
		logoWidthM: '50px', // Logo width on medium screens, 768px - 1024px
		logoWidthL: '60px', // Logo width on large screens, 1024px - 1440px
		logoWidthXL: '60px', // Logo width on extra large screens, above 1440px
		logoHeightXS: '40px', // Logo height on extra small screens, up to 480px
		logoHeightS: '50px', // Logo height on small screens, 480px - 768px
		logoHeightM: '50px', // Logo height on medium screens, 768px - 1024px
		logoHeightL: '60px', // Logo height on large screens, 1024px - 1440px
		logoHeightXL: '60px', // Logo height on extra large screens, above 1440px
		iconsFooter: '32px', // Sets the icons size for the footer
		iconsHeader: '20px' // Sets the icons size for the header
	},
	styles: {
		h1: {
			fontFamily: 'heading',
			fontWeight: 'heading',
			lineHeight: 'heading',
			m: 0,
			mb: 1,
			fontSize: 6,
			mt: 4,
			'::after': {
				display: 'block',
				content: '""',
				width: '60px',
				pt: 2
				// borderBottomStyle: "solid",
				// borderBottomWidth: "4px",
				// borderBottomColor: "primary",
			}
		},
	},
	variants: {
		text: {
			heading: {
				color: '#3d709c'
			}
		},
		contentContainer: {
			m: 0,
			p: 0,
			maxWidth: '100vw'
		},
		siteTitle: {
			fontSize: [ 3, 4, null, 5, null ],
			'::after': {
				content: 'none'
			}
		},
		navLink: {
			fontFamily: 'heading',
			fontWeight: '400',
			'::after': {
				position: 'absolute',
				top: '100%',
				left: '0',
				width: '100%',
				height: '1px',
				color: '#3d709c',
				content: "''",
				opacity: '0',
				transition: 'height 0.3s, opacity 0.3s, transform 0.3s',
				transform: 'translateY(-6px)'
			},
			':hover, :focus, :active': {
				textDecoration: 'none',
				color: '#69af95'
			},
			':hover::after, :active::after, :focus::after': {
				height: '4px',
				opacity: '1',
				transform: 'translateY(0px)'
			}
		},
		navLinkActive: {
			textDecoration: 'none',
			color: '#3d709c',
			'::after': {
				position: 'absolute',
				top: '100%',
				left: '0',
				width: '100%',
				height: '4px',
				content: "''",
				opacity: '1',
				transform: 'translateY(0px)'
			}
		},
		navLinkSub: {
			'::after': {
				content: 'none'
			},
			':hover::after': {
				content: 'none'
			},
			':hover, :focus, :active': {
				color: '#69af95'
			}
		},
		navLinkSubActive: {
			textDecoration: 'none',
			color: '#3d709c',
			'::after': {
				content: 'none'
			}
		}
	}
});
